import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { Layout } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { Calculator } from '@src/containers/calculator/PageHeader/Calculator';
import { PageHeader } from '@src/containers/list/PageHeader';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsCalculatorVisible } from '@src/utils/checkIsCalculatorVisible';

import styles from '../list/styles.module.scss';

const SeoComponents = dynamic(() => import('@src/components/SeoComponents'));

const pageConfig: IPageContextConfig = {
    listKey: ListKeys.ZAIMY_POD_PTS,
    source: 'search',
    sortPanelVisible: true,
    seoLinksVisible: true,
    extraFiltersVisible: true,
    cardTermIsVisible: true,
    withBanners: true,
    withInvitationBanner: true,
    withEmailBurner: true,
    withSelectionBanner: true,
    withCardProductLink: true,
};

const PodPtsPage: NextPage = () => {
    const isMobile = useIsMobile();
    const { filter, seo, pathname } = useListPage(ListKeys.ZAIMY_POD_PTS);
    const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);

    useLoadRestOffers(ListKeys.ZAIMY_POD_PTS);
    useBestProducts();

    const { asPath } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');
    const hasUtmLabel = useHasUtmLabel();

    const isCalculatorVisible = checkIsCalculatorVisible(pathWithoutQuery) && !hasUtmLabel;

    return (
        <PageContext.Provider value={config}>
            <PageHeader className={styles.page_header} seo={seo} filter={filter} />
            <ProductList className={styles.product_list} filter={filter} pathname={pathname} />
            {isCalculatorVisible && (
                <Layout className={styles.calculator_layout}>
                    <Calculator hasHeadingBlock filters={filter.filters} />
                </Layout>
            )}
            {seo && <SeoComponents seo={seo} breadcrumbs={seo.breadcrumbs} prefooter={seo.prefooter} />}
            <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
        </PageContext.Provider>
    );
};

PodPtsPage.getInitialProps = async (ctx: Application.ReduxNextPageContext) => {
    await getPageListData(ctx, {
        key: ListKeys.ZAIMY_POD_PTS,
        defaultFiltersMode: MODES.ZAIMY_POD_PTS,
    });
};

export default PodPtsPage;
